<template>
  <el-select v-model="majorId" @change="handleChange" placeholder="请选择" style="width:100%">
    <el-option
        v-for="major in majors"
        :key="major.id"
        :label="major.name"
        :value="major.id">
    </el-option>
  </el-select>
</template>

<script>
import {getLocalProjectId, UrlEnum} from "../../public/js/common-vue";

export default {
  name: "MajorSelect",
  data(){
    return{
      majorId:'',
      majors:[]
    }
  },
  methods:{
    initData(checkedVal){
      this.$http({
        method: "get",
        url: UrlEnum.MAJORS,
      }).then((res) => {
        this.majors = res.data.list;
        if(checkedVal != null){
          this.majorId = checkedVal;
        }
        this.handleChange();
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    reset(){
      this.majorId='';
      this.handleChange();
    },
    handleChange(){
      this.$emit('parentEvent',this.majorId)
    }
  },
  mounted() {}
}
</script>

<style scoped>
</style>
