<template>
<div id="abc" class="select-main">
  <div class="header">
    <el-page-header @back="goBack"></el-page-header>
    <div id="selected-num" style="">已选（{{this.selectedQuestions.length}}）</div>
  </div>
  <div style="clear: both"></div>
  <div class="div-tree">
    <iframe class="iframe-tree" src="../../knowledge/tree1.html?20210301"></iframe>
  </div>
  <div class="div-right">
    <loading :loading="loading" text="搜索中"></loading>
      <el-form ref="form"    label-width="100px">
           <el-form-item label="题目ID" style="width: 300px; margin-bottom:10px;display: inline-block">
              <el-input type="number"  v-model="id" placeholder="请输入" style="width: 200px"></el-input>
            </el-form-item>
           <el-form-item label="题干" style="width: 300px; margin-bottom:10px;display: inline-block">
            <el-input type="text"  v-model="stem" placeholder="请输入" style="width: 200px"></el-input>
          </el-form-item>
        <el-form-item label="题型" style="width: 300px; margin-bottom:10px;display: inline-block" v-show="this.typeIds=='4,5,6'">
          <select-type ref="selectType" @parentEvent="getTypeId"></select-type>
        </el-form-item>
        <el-form-item label="专业" style="width: 300px; margin-bottom:10px;display: inline-block" v-show="projectId == projectEnum.FA_SHUO">
          <major-select ref="majorSelect" @parentEvent="getMajorId"></major-select>
        </el-form-item>

          <source-form-item ref="sourceFormItem" @parentEvent="getSourceId" :model="sourceId" :parent="this" prop="sourceId" style="width: 300px;display: inline-block" ></source-form-item>
           <div style="display:inline-block;margin-left: 60px">
            <el-button type="primary" @click="getData">搜索</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </div>
        <el-form-item></el-form-item>
      </el-form>
      <div id="divList" class="layui-card-body">
        <el-table border :data="questions" style="width: 100%" v-show="tableShow">
          <el-table-column prop="id" label="ID" align="center" width="70"></el-table-column>
          <el-table-column prop="stem" label="题干" align="left"></el-table-column>
          <el-table-column prop="typeName" label="题型" align="center" width="70"></el-table-column>
          <el-table-column prop="difficultName" label="难易度" align="center" width="70"></el-table-column>
          <el-table-column fixed="right" label="操作" width="70" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClickSelected(scope.row)" type="text" size="small" v-show="!checkIsSelected(scope.row)" >选择</el-button><el-button @click="handleClickUnSelected(scope.row)" type="text" size="small" v-show="checkIsSelected(scope.row)" style="color: #DD691D" >已选</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="pageCurrentChange"
            style="text-align: center;padding: 20px;"
            v-show="tableShow"
        >
        </el-pagination>
      </div>
  </div>
</div>
</template>

<script>
import {UrlEnum, getLocalProjectId, request, QuestionTypeEnum,ProjectEnum} from "../../../public/js/common-vue";
import SourceFormItem from "@/components/SourceFormItem";
import SelectType from "@/components/SelectType";
import Loading from "@/components/Loading";
import MajorSelect from "../../components/MajorSelect";
export default {
  name: "SelectQuestion",
  components: {MajorSelect, Loading, SourceFormItem,SelectType},
  data() {
    return {
      loading:false,
      questions:[],
      total:0,
      page:1,
      size:10,
      subjectId:0,
      chapterId:0,
      id:'',
      stem:'',
      typeId:'',
      majorId:'',
      sourceId:'',
      typeIds:'',
      projectEnum:ProjectEnum,
      projectId:getLocalProjectId(),
      selectedQuestions:[],
      tableShow:false
    }
  },
  methods:{
    handleClickSelected(row){
      this.selectedQuestions.push({id:row.id,stem:row.stem,type:row.type});
     },
    handleClickUnSelected(row){
      for(let i=0;i<this.selectedQuestions.length;i++){
        if(row.id == this.selectedQuestions[i].id){
          this.selectedQuestions.splice(i,1);
        }
      }
    },
    goBack() {
      // this.$route.params.selectedQuestions=this.selectedQuestions;
      this.$router.back();
    },
    checkIsSelected(row){
      for(let i=0; i<this.selectedQuestions.length; i++){
        if(this.selectedQuestions[i].id == row.id){
          return true;
        }
      }
      return false;
    },
    getSourceId(data){
      this.sourceId = data;
      console.log(data);
    },
    getTypeId(data){
      this.typeId = data;
    },
    getMajorId(data){
      this.majorId = data;
    },
    pushQuestion(question){
      this.$router.push({
        name: 'MockPaperSave',
        params: question
      })
    },
    pageCurrentChange(val,e,form,jobExpLabel,pageform,searchVal) {
      this.page = val;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.QUESTION + "?projectId=" + getLocalProjectId() + "&typeIds=" + this.typeIds + "&subjectId=" + this.subjectId;
      if(this.chapterId != 0){
        url += "&chapterId="+this.chapterId;
      }
      url += "&page="+this.page+"&size="+this.size;
      if(this.id != ''){
        url += "&id="+this.id;
      }
      if(this.stem != ''){
        url += "&stem="+this.stem;
      }
      if(this.typeId != ''){
        url += "&typeId="+this.typeId
      }
      if(this.sourceId != ''){
        url += "&sourceId="+this.sourceId
      }
      if(this.majorId != ''){
        url += "&majorId="+this.majorId
      }
      console.log(url);
      return url;
    },
    reset(){
      this.page=1;
      this.id='';
      this.stem='';
      this.typeId='';
      this.sourceId='';
      this.$refs.majorSelect.reset();
      try{
        this.$refs.sourceFormItem.initData();
      }
      catch (e){
      }
      this.getData();
    },
    getData() {
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.total = res.data.total;
        let list = res.data.list;
        for(var i=0;i<list.length;i++){
          var length = list[i].stem.length;
          list[i].stem = list[i].stem.substring(0,100);
          if(length > 100){
            list[i].stem += "...";
          }
        }
        this.questions = list;
        this.tableShow=true;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    }
  },
  mounted() {
    this.$refs.sourceFormItem.initData();
    this.$refs.selectType.initData();
    this.$refs.majorSelect.initData();
    this.typeIds = this.$route.params.typeIds;
    this.selectedQuestions = this.$route.params.selectedQuestions;
    // 子页面接收消息
    var that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "getData"){
        that.getData();
      }
      else if(e.data.key == 'reset'){
        that.reset();
      }
      else if(e.data.key == 'setSubjectId'){
        that.subjectId = e.data.msg
      }
      else if(e.data.key == 'setChapterId'){
        that.chapterId = e.data.msg;
      }
    }, false)
  }
}
</script>

<style>
html,body{
  height: 100%;
  background: white;
  overflow: hidden;
}
.div-tree{
  width: 250px;
  height: calc(100% - 40px);
  float: left;
  overflow:hidden;
  border:1px solid #f6f6f6;
  padding-right: 0px;
  background-color: #fdfdfd;
  margin-top:40px;
}
.iframe-tree{
  width:100%;
  height: 100%;
  border: 0px;
  overflow:hidden
}
.div-right{
  float: left;
  width:calc(100% - 272px);
  background: white;
  padding:10px;
  height: calc(100% - 40px);
  margin-top:40px;
  overflow: auto;
}

.header{
  height: 45px;
  width: calc(100% - 30px);
  background-color: #ffffff;
  position: absolute;
}
.el-page-header {
  display: flex;
  line-height: 45px;
  margin-left: 20px;
  float: left;
}
.el-page-header__left {
  display: flex;
  cursor: pointer;
  margin-right: 20px;
  position: absolute;
  width: 100%;
  height: 45px !important;
  line-height: 45px !important;
}
.select-main{
  height: 100%;
}
#selected-num{
  float: right;
  height: 45px;
  line-height: 45px;
  color: #DD691D;
  font-size: 14px;
  font-weight: bold;
}
</style>
